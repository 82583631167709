import React, { createContext, useEffect, useState } from 'react'

import { breakpoints } from 'helpers/media-queries'

export const LayoutContext = createContext()

const LayoutProvider = ({ children }) => {
  const [mobileScreen, setMobileScreen] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setMobileScreen(window.innerWidth <= breakpoints.mobile)
    }
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <LayoutContext.Provider
      value={{
        mobileScreen
      }}
    >
      {children}
    </LayoutContext.Provider>
  )
}

export default LayoutProvider
