import styled, { createGlobalStyle } from 'styled-components'
import { mediaMax, mediaMin, calculateResponsiveSize, calculateMobileResponsiveSize } from 'helpers/media-queries'

const GlobalStyle = createGlobalStyle`
  :root {
    --DBOX-BLACK: #232323;
    --DBOX-BLUE: #03041F;
    --DBOX-GRAY: #ABABAB;
    --DBOX-WHITE: #FAFAFA;
  }

  html, body{
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    font-size: ${calculateResponsiveSize(18)};
    line-height: ${calculateResponsiveSize(24)};
    color: var(--DBOX-WHITE, #FAFAFA);
    background: var(--DBOX-BLUE, #03041F);
    min-width: 320px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    ${mediaMax('mobile')} {
      font-size: ${calculateMobileResponsiveSize(14)};
      line-height: ${calculateMobileResponsiveSize(18)};
    }
  }
  body{
    overflow-y: scroll;
  }
  body.hidden{
    overflow: hidden;
  }
  html, body, a, input, select, textarea{
    font-family: "Graphik", Helvetica, Arial, Tahoma, Geneva, Kalimati, sans-serif;
    font-weight: 300;
  }
  a{
    text-decoration: none;
    color: var(--DBOX-WHITE, #FAFAFA);
    transition: all 0.25s ease 0s;
    outline: none;
  }
  a:hover{
    color: var(--DBOX-WHITE, #FAFAFA);
    text-decoration: none;
  }
  input, select, textarea {
    vertical-align: top;
    outline: none;
  }
  textarea {
    resize: vertical;
  }
  *{ -webkit-tap-highlight-color: transparent; box-sizing: border-box; }
  img, object, iframe, svg{ border: 0; vertical-align: top; }
  svg path, svg circle, svg line{ transition: all 0.25s ease 0s; }

  .mobile-only {
    ${mediaMin('mobile')} {
      display: none;
    }
  }

  .desktop-only {
    ${mediaMax('mobile')} {
      display: none;
    }
  }
`

export const Heading1 = styled.h1`
  font-size: ${calculateResponsiveSize(36)};
  font-style: normal;
  font-weight: 400;
  line-height: ${calculateResponsiveSize(44)}; /* 122.222% */
  letter-spacing: ${calculateResponsiveSize(2.52)};
  text-transform: uppercase;
  margin: 0;

  ${mediaMax('mobile')} {
    font-size: ${calculateMobileResponsiveSize(20)};
    font-style: normal;
    font-weight: 400;
    line-height: ${calculateMobileResponsiveSize(24)}; /* 120% */
    letter-spacing: ${calculateMobileResponsiveSize(1.4)};
    text-transform: uppercase;
  }
`

export const Heading2 = styled.h2`
  font-size: ${calculateResponsiveSize(32)};
  font-style: normal;
  font-weight: 400;
  line-height: ${calculateResponsiveSize(40)}; /* 125% */
  letter-spacing: ${calculateResponsiveSize(2.24)};
  text-transform: uppercase;
  margin: 0 0 ${calculateResponsiveSize(24)} 0;

  ${mediaMax('mobile')} {
    font-size: ${calculateMobileResponsiveSize(16)};
    font-style: normal;
    font-weight: 400;
    line-height: ${calculateMobileResponsiveSize(20)}; /* 125% */
    letter-spacing: ${calculateMobileResponsiveSize(1.12)};
    text-transform: uppercase;
    margin: 0 0 ${calculateMobileResponsiveSize(12)} 0;
  }
`

export const Heading2Light = styled.h2`
  font-size: ${calculateResponsiveSize(32)};
  font-style: normal;
  font-weight: 300;
  line-height: ${calculateResponsiveSize(36)}; /* 112.5% */
  text-transform: uppercase;
  margin: 0 0 ${calculateResponsiveSize(24)} 0;

  ${mediaMax('mobile')} {
    font-size: ${calculateMobileResponsiveSize(16)};
    font-style: normal;
    font-weight: 300;
    line-height: ${calculateMobileResponsiveSize(20)}; /* 125% */
    text-transform: uppercase;
    margin: 0 0 ${calculateMobileResponsiveSize(24)} 0;
  }
`

export const Heading3 = styled.h3`
  font-size: ${calculateResponsiveSize(24)};
  font-style: normal;
  font-weight: 500;
  line-height: ${calculateResponsiveSize(28)}; /* 116.667% */
  letter-spacing: ${calculateResponsiveSize(1.68)};
  text-transform: uppercase;
  margin: 0;

  ${mediaMax('mobile')} {
    font-size: ${calculateMobileResponsiveSize(14)};
    font-style: normal;
    font-weight: 500;
    line-height: ${calculateMobileResponsiveSize(24)}; /* 171.429% */
    letter-spacing: ${calculateMobileResponsiveSize(0.98)};
    text-transform: uppercase;
  }
`

export const HeadingSmall = styled.div`
  font-size: ${calculateResponsiveSize(14)};
  font-style: normal;
  font-weight: 600;
  line-height: ${calculateResponsiveSize(18)}; /* 128.571% */
  letter-spacing: ${calculateResponsiveSize(1.4)};
  text-transform: uppercase;

  ${mediaMax('mobile')} {
    font-size: ${calculateMobileResponsiveSize(10)};
    font-style: normal;
    font-weight: 600;
    line-height: ${calculateMobileResponsiveSize(14)}; /* 140% */
    letter-spacing: ${calculateMobileResponsiveSize(1)};
    text-transform: uppercase;
  }
`

export const BodyCopy = styled.div`
  font-size: ${calculateResponsiveSize(18)};
  font-style: normal;
  font-weight: 300;
  line-height: ${calculateResponsiveSize(24)}; /* 133.333% */

  ${mediaMax('mobile')} {
    font-size: ${calculateMobileResponsiveSize(14)};
    font-style: normal;
    font-weight: 300;
    line-height: ${calculateMobileResponsiveSize(18)}; /* 128.571% */
  }
`

export default GlobalStyle
