import styled from 'styled-components'
import { Link } from 'gatsby'
import { mediaMax, calculateResponsiveSize, calculateMobileResponsiveSize } from 'helpers/media-queries'
import LogoImage from 'icons/logo.svg'
import LogoImageWhite from 'icons/logo-white.svg'

export const HeaderWrapper = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: ${calculateResponsiveSize(0)} ${calculateResponsiveSize(32)};
  z-index: 11;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: ${calculateResponsiveSize(85)};

  ${mediaMax('mobile')} {
    height: ${calculateMobileResponsiveSize(60)};
    padding: ${calculateMobileResponsiveSize(0)} ${calculateMobileResponsiveSize(20)};
  }
`

export const Logo = styled(Link)`
  display: block;
  width: ${calculateResponsiveSize(85)};
  height: ${calculateResponsiveSize(21)};
  background-image: url(${LogoImage});
  background-repeat: no-repeat;
  background-position: left center;
  background-size: contain;
  transition: all .4s linear;
  &:hover {
    background-image: url(${LogoImageWhite});
  }

  ${mediaMax('mobile')} {
    width: ${calculateMobileResponsiveSize(82)};
    height: ${calculateMobileResponsiveSize(20)};
  }

  img {
    height: 100%;
    color: var(--DBOX-GRAY, #ABABAB);
  }
`

export const Menu = styled.div`
  position: relative;
  cursor: pointer;
  height: ${calculateResponsiveSize(30)};
  width: ${calculateResponsiveSize(30)};
  display: flex;
  align-items: center;

  ${mediaMax('mobile')} {
    height: ${calculateMobileResponsiveSize(20)};
    width: ${calculateMobileResponsiveSize(30)};
  }
`

export const NavIcon = styled.div`
  position: relative;
  height: ${calculateResponsiveSize(2)};
  width: ${calculateResponsiveSize(30)};

  &::before, &::after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    content: "";
    height: ${calculateResponsiveSize(2)};
    width: ${calculateResponsiveSize(30)};
    background: var(--DBOX-GRAY, #ABABAB);
    transition: all .25s ease;
  }
  &::before {
    transform: translateY(${calculateResponsiveSize(-3)});
  }
  &::after {
    transform: translateY(${calculateResponsiveSize(3)});
  }
  .burger:hover &::before {
    background: var(--DBOX-WHITE, #FAFAFA) !important;
    transform: translateY(${calculateResponsiveSize(-6)});
  }
  .burger:hover &::after {
    background: var(--DBOX-WHITE, #FAFAFA) !important;
    transform: translateY(${calculateResponsiveSize(6)});
  }
  .open & {
    &::before {
      transform: rotate(45deg) !important;
    }
    &::after {
      transform: rotate(-45deg) !important;
    }
  }

  ${mediaMax('mobile')} {
    height: ${calculateMobileResponsiveSize(1)};
    width: ${calculateMobileResponsiveSize(30)};

    &::before, &::after {
      height: ${calculateMobileResponsiveSize(1)};
      width: ${calculateMobileResponsiveSize(30)};
    }
    &::before {
      transform: translateY(${calculateMobileResponsiveSize(-3)});
    }
    &::after {
      transform: translateY(${calculateMobileResponsiveSize(3)});
    }
    .burger:hover &::before {
      transform: translateY(${calculateMobileResponsiveSize(-5)});
    }
    .burger:hover &::after {
      transform: translateY(${calculateMobileResponsiveSize(5)});
    }
  }
`

export const FullscreenMenu = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display:flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background: var(--DBOX-BLUE, #03041F);
  opacity: 0;
  visibility: hidden;
  transition: all .4s ease;
  z-index: 10;
  &.open {
    overflow-y: scroll;
    opacity: 1;
    visibility: visible;
  }
`

export const MenuUl = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`

export const MenuLi = styled.li`
  padding: ${calculateResponsiveSize(24)} 0;
  text-align: center;
  font-size: ${calculateResponsiveSize(40)};
  font-style: normal;
  font-weight: 275;
  line-height: ${calculateResponsiveSize(44)}; /* 110% */
  letter-spacing: ${calculateResponsiveSize(2.8)};
  text-transform: uppercase;
  &.animate {
    opacity: 0;
    transform: translateY(${calculateResponsiveSize(30)});
    transition: all .5s;
  }
  &.animate.show {
    transform: translateY(0);
    opacity: 1;
  }
  a {
    text-decoration: none !important;
    color: var(--DBOX-WHITE, #FAFAFA);
    transition: all .2s linear;
    &:not(.active):hover {
      color: var(--DBOX-WHITE, #FAFAFA);
    }
  }

  ${mediaMax('mobile')} {
    font-size: ${calculateMobileResponsiveSize(28)};
    line-height: ${calculateMobileResponsiveSize(32)}; /* 114.286% */
    letter-spacing: ${calculateMobileResponsiveSize(1.96)};
    padding: ${calculateMobileResponsiveSize(16)} 0;
  }
`

export const MenuLink = styled(Link)`
`

export const Social = styled.div`
  position: absolute;
  bottom: ${calculateResponsiveSize(80)};
  margin: 0;
  padding: 0;

  display: flex;
  flex-direction: row;
  column-gap: ${calculateResponsiveSize(24)};

  ${mediaMax('mobile')} {
    column-gap: ${calculateMobileResponsiveSize(17)};
  }
`

export const SocialLink = styled(Link)`
  color: var(--DBOX-GRAY, #ABABAB);
  width: ${calculateResponsiveSize(14)};
  height: ${calculateResponsiveSize(14)};

  ${mediaMax('mobile')} {
    width: ${calculateMobileResponsiveSize(10)};
    height: ${calculateMobileResponsiveSize(10)};
  }

  img {
    width: ${calculateResponsiveSize(14)};
    height: ${calculateResponsiveSize(14)};

    ${mediaMax('mobile')} {
      width: ${calculateMobileResponsiveSize(10)};
      height: ${calculateMobileResponsiveSize(10)};
    }
  }
`
