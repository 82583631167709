import React from 'react'
import PropTypes from 'prop-types'
import { Transition, TransitionGroup } from 'react-transition-group'
import Header from 'components/header'
// import ArrowTop from 'components/ui/arrow-top'

import 'helpers/fonts.css'
import GlobalStyle from 'styles/global-styles'
import { LayoutWrapper, PageWrapper, ContentWrapper } from './index.styled'
import LayoutProvider from 'providers/layout-provider'

const LayoutContent = ({ children, location }) => {
  return (
    <TransitionGroup component={null}>
      <Transition key={location.pathname} timeout={{ enter: 250, exit: 250 }}>
        {(status) => (
          <div className={`page ${status}`}>
            <LayoutWrapper>{children}</LayoutWrapper>
          </div>
        )}
      </Transition>
    </TransitionGroup>
  )
}

const Layout = (props) => {
  const {
    children,
    location
    // pageContext: { layout }
  } = props

  // const [showArrow, setShowArrow] = useState(false)

  // const handleScroll = () => {
  //   setShowArrow(window.scrollY > window.innerHeight / 2)
  // }

  // useEffect(() => {
  //   window.addEventListener('scroll', handleScroll)
  //   return () => window.removeEventListener('scroll', handleScroll)
  // }, [])

  // const scrollToTop = () => {
  //   window.scrollTo({
  //     top: 0,
  //     behavior: 'smooth'
  //   })
  // }

  return (
    <LayoutProvider>
      <div className='home-wrapper'>
        <Header location={location} />
        {/* <ArrowTop show={showArrow} onClick={scrollToTop} /> */}
        <div className='home-content'>
          <PageWrapper>
            <GlobalStyle />
            <ContentWrapper>
              <LayoutContent location={location}>{children}</LayoutContent>
            </ContentWrapper>
          </PageWrapper>
        </div>
      </div>
    </LayoutProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired
}

export default Layout
