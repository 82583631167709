import React, { useState, useEffect } from 'react'
import {
  HeaderWrapper,
  Logo,
  Menu,
  NavIcon,
  FullscreenMenu,
  MenuUl,
  MenuLi,
  MenuLink,
  Social,
  SocialLink
} from './index.styled'

import IconFacebook from 'icons/facebook.svg'
import IconInstagram from 'icons/instagram.svg'
import IconLinkedin from 'icons/linkedin.svg'

const Header = ({ location }) => {
  const isAboutPage = location.pathname === '/about/'
  const isPrivacyPolicyPage = location.pathname === '/privacy-policy/'
  const isHeaderBlack = isAboutPage || isPrivacyPolicyPage
  const isTeamPage = location.pathname === '/team/'
  const isLeadershipPage = location.pathname === '/leadership/'
  const isContactPage = location.pathname === '/contact/'
  const isFullscreenMenuBlack = isTeamPage || isLeadershipPage || isContactPage

  const [menuOpen, setMenuOpen] = useState(false)

  const calculateScrollbarWidth = () => {
    return window.innerWidth - document.documentElement.clientWidth
  }

  const handleMenuClick = () => {
    setMenuOpen(!menuOpen)
  }

  useEffect(() => {
    const width = calculateScrollbarWidth()

    document.body.classList.toggle('hidden', menuOpen)
    document.body.style.paddingRight = menuOpen ? `${width}px` : '0'
    document.getElementById('header').style.right = menuOpen ? `${width}px` : '0'
  }, [menuOpen])

  useEffect(() => {
    const menuItems = document.querySelectorAll('.animate')
    menuItems.forEach((item, index) => {
      const delay = menuOpen ? index * 0.1 : 0
      setTimeout(() => {
        item.classList.toggle('show', menuOpen)
      }, delay * 1000)
    })
  }, [menuOpen])

  return (
    <>
      <HeaderWrapper
        id='header'
        className={`${isHeaderBlack ? 'black' : ''}`}
      >
        <Logo to='/' onClick={() => setMenuOpen(false)} />
        <Menu
          className={`burger ${menuOpen ? 'open' : ''}`}
          onClick={handleMenuClick}
        >
          <NavIcon />
        </Menu>
      </HeaderWrapper>
      <FullscreenMenu className={`${isFullscreenMenuBlack ? 'black' : ''} ${menuOpen ? 'open' : ''}`}>
        <MenuUl>
          <MenuLi className='animate'>
            <MenuLink to='/team' onClick={handleMenuClick} className={`${isTeamPage ? 'active' : ''}`}>Team</MenuLink>
          </MenuLi>
          <MenuLi className='animate'>
            <MenuLink to='/about' onClick={handleMenuClick} className={`${isAboutPage ? 'active' : ''}`}>About</MenuLink>
          </MenuLi>
          <MenuLi className='animate'>
            <a href='https://www.dboxcg.com/' onClick={handleMenuClick}>DBOX CG</a>
          </MenuLi>
          <MenuLi className='animate'>
            <MenuLink to='/contact' onClick={handleMenuClick} className={`${isContactPage ? 'active' : ''}`}>Contact</MenuLink>
          </MenuLi>
          {/* <MenuLi className='animate'>
            <MenuLink to='/contact' onClick={handleMenuClick} className={`${isContactPage ? 'active' : ''}`}>Legal</MenuLink>
          </MenuLi> */}
        </MenuUl>
        <Social>
          <SocialLink href='https://www.facebook.com/dboxglobal' target='_blank'><img src={IconFacebook} alt='facebook' /></SocialLink>
          <SocialLink href='https://www.linkedin.com/company/dboxglobal/' target='_blank'><img src={IconLinkedin} alt='linkedin' target='_blank' /></SocialLink>
          <SocialLink href='https://instagram.com/DBOXGLOBAL' target='_blank'><img src={IconInstagram} alt='instagram' target='_blank' /></SocialLink>
        </Social>
      </FullscreenMenu>
    </>
  )
}

export default Header
